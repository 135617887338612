import logo from './logo.svg';
import './App.css';
import HeroSection from "./components/HeroSection";
import Navbar from "./components/Navbar";
import AboutSection from "./components/AboutSection";
// import EmailSection from "./components/EmailSection";
import Footer from "./components/Footer";
import AchievementsSection from "./components/AchievementsSection";
import MaterialsSection from "./components/MaterialSection";
import Services from "./components/Services";

function App() {
  return (
    <div className="App">
      <main className="flex min-h-screen flex-col bg-[#121212]">
      <Navbar />
      <div className="container mt-24 mx-auto px-12 py-4">
        <HeroSection />
        <MaterialsSection />
        <AchievementsSection />
        <AboutSection />
        <Services />
        {/* <EmailSection /> */}
      </div>
      <Footer />
    </main>
    </div>
  );
}

export default App;
