"use client";
import React, { useState, useRef } from "react";
import MaterialCard from "./MaterialCard";
import MaterialTag from "./MaterialTag";
import { motion, useInView } from "framer-motion";
import './MaterialSection.css';

const materialsData = [
  {
    id: 1,
    title: "Hoody",
    description: "Material 1 description",
    image: "/images/materials/1.jpg",
    tag: ["Men", "Women"],
    previewUrl: "/",
  },
  {
    id: 2,
    title: "Jeans",
    description: "Material 2 description",
    image: "/images/materials/2.jpg",
    tag: ["Men"],
    previewUrl: "/",
  },
  {
    id: 3,
    title: "Tops",
    description: "Material 3 description",
    image: "/images/materials/1.jpg",
    tag: ["Women"],
    previewUrl: "/",
  },
  {
    id: 4,
    title: "Knickers",
    description: "Material 4 description",
    image: "/images/materials/2.jpg",
    tag: ["Children"],
    previewUrl: "/",
  },
  {
    id: 5,
    title: "T-shirt",
    description: "Material 5 description",
    image: "/images/materials/1.jpg",
    tag: ["Men", "Women"],
    previewUrl: "/",
  },
  {
    id: 6,
    title: "Sweat Shirt",
    description: "Material 5 description",
    image: "/images/materials/2.jpg",
    tag: ["Men", "Women"],
    previewUrl: "/",
  },
  {
    id: 7,
    title: "T-shirts",
    description: "Material 7 description",
    image: "/images/materials/1.jpg",
    tag: ["Children"],
    previewUrl: "/",
  },
];

const MaterialsSection = () => {
  const [tag, setTag] = useState("Men");
  const ref = useRef(null);
  const isInView = useInView(ref); //, { once: true } for one time animation

  const handleTagChange = (newTag) => {
    setTag(newTag);
  };

  const filteredMaterials = materialsData.filter((material) =>
    material.tag.includes(tag)
  );

  const cardVariants = {
    initial: { y: 50, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    setShowSnackbar(true);
    // Hide the snackbar after 3 seconds
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
  };

  return (
    <section id="products" className="pb-8">
        {/* <h2 className="text-center text-4xl font-bold text-white mt-4 mb-8 md:mb-12">
          Our Collection
        </h2> */}
    
      <div id="cards">
        <div>
          {/* <div className=" rounded-md sm:rounded-lg lg:rounded-xl"> */}
            <h2 className="text-4xl bg-gradient-to-r from-zinc-200 via-zinc-800 to-zinc-200 text-center font-extrabold m-2 p-2 text-transparent bg-clip-text ">
              Men&apos;s
            </h2>
          {/* </div> */}
          
          <div className="card" data-color="blue" onClick={handleClick}>
            <div>
            <img className="card-front-image card-image" src="/images/men-p.png" />

            </div>
            <div className="card-faders">
              <img className="card-fader card-image" src="/images/men-p.png" />
              <img className="card-fader card-image" src="/images/men-p.png" />
              <img className="card-fader card-image" src="/images/men-p.png" />
              <img className="card-fader card-image" src="/images/men-p.png" />
              <img className="card-fader card-image" src="/images/men-p.png" />
              <img className="card-fader card-image" src="/images/men-p.png" />
              <img className="card-fader card-image" src="/images/men-p.png" />
              <img className="card-fader card-image" src="/images/men-p.png" />
            </div>
          </div>
          {showSnackbar && (
            <div className=" text-white rounded">
              <p className="text-2xl bg-gradient-to-r from-zinc-200 via-zinc-800 to-zinc-200 text-center font-bold m-2 p-2 text-transparent bg-clip-text ">Coming Soon!</p>
            </div>
          )}
        </div>
        
        <div>
        {/* <div className="bg-gradient-to-r from-primary-300 to-secondary-700 rounded-md sm:rounded-lg lg:rounded-xl"> */}
            <h2 className="text-4xl bg-gradient-to-r from-rose-400 via-rose-800 to-rose-200 text-center font-extrabold m-2 p-2 text-transparent bg-clip-text">
              Women&apos;s
            </h2>
          {/* </div> */}
          <div className="card" data-color="green" onClick={handleClick}>
          <img className="card-front-image card-image" src="/images/women-p.png" />
          <div className="card-faders">
            <img className="card-fader card-image" src="/images/women-p.png" />
            <img className="card-fader card-image" src="/images/women-p.png" />
            <img className="card-fader card-image" src="/images/women-p.png" />
            <img className="card-fader card-image" src="/images/women-p.png" />
            <img className="card-fader card-image" src="/images/women-p.png" />
            <img className="card-fader card-image" src="/images/women-p.png" />
            <img className="card-fader card-image" src="/images/women-p.png" />
            <img className="card-fader card-image" src="/images/women-p.png" />
          </div>
        </div>
        {showSnackbar && (
            <div className=" text-white rounded">
              <p className="text-2xl bg-gradient-to-r from-rose-400 via-rose-800 to-rose-200 text-center font-bold m-2 p-2 text-transparent bg-clip-text">Coming Soon!</p>
            </div>
          )}
        </div>
       
       <div>
        {/* <div className="bg-gradient-to-r from-primary-300 to-secondary-700 rounded-md sm:rounded-lg lg:rounded-xl"> */}
          <h2 className="text-4xl bg-gradient-to-r from-sky-200 via-sky-800 to-sky-200 text-center font-extrabold m-2 p-2 text-transparent bg-clip-text ">
            Kid&apos;s
          </h2>
        {/* </div> */}
        <div className="card" data-color="brown" onClick={handleClick}>
          <img className="card-front-image card-image" src="/images/kid-p.png" />
          <div className="card-faders">
            <img className="card-fader card-image" src="/images/kid-p.png" />
            <img className="card-fader card-image" src="/images/kid-p.png" />
            <img className="card-fader card-image" src="/images/kid-p.png" />
            <img className="card-fader card-image" src="/images/kid-p.png" />
            <img className="card-fader card-image" src="/images/kid-p.png" />
            <img className="card-fader card-image" src="/images/kid-p.png" />
            <img className="card-fader card-image" src="/images/kid-p.png" />
            <img className="card-fader card-image" src="/images/kid-p.png" />
          </div>
        </div>
        {showSnackbar && (
            <div className=" text-white rounded">
              <p className="text-2xl bg-gradient-to-r from-sky-200 via-sky-800 to-sky-200 text-center font-bold m-2 p-2 text-transparent bg-clip-text ">Coming Soon!</p>
            </div>
          )}
       </div>
        
      </div>

      {/* <div className="text-white flex flex-row justify-center items-center gap-2 py-6">
        <MaterialTag
          onClick={handleTagChange}
          name="Men"
          isSelected={tag === "Men"}
        />
        <MaterialTag
          onClick={handleTagChange}
          name="Women"
          isSelected={tag === "Women"}
        />
        <MaterialTag
          onClick={handleTagChange}
          name="Children"
          isSelected={tag === "Children"}
        />
      </div> */}
      {/* <ul ref={ref} className="grid md:grid-cols-3 gap-8 md:gap-12">
        {filteredMaterials.map((material, index) => (
          <motion.li
            key={index}
            variants={cardVariants}
            initial="initial"
            animate={isInView ? "animate" : "initial"}
            transition={{ duration: 0.3, delay: index * 0.4 }}
          >
            <MaterialCard
              key={material.id}
              index={material.id}
              title={material.title}
              description={material.description}
              imgUrl={material.image}
              previewUrl={material.previewUrl}
            />
          </motion.li>
        ))}
      </ul> */}
    </section>
  );
};

export default MaterialsSection;
