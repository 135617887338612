import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const ServiceCard = ({ id, service, imgurl, desc }) => {
    const ref = useRef(null);
    const [isInView, setIsInView] = useState(false);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      });

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                const { top } = ref.current.getBoundingClientRect();
                setIsInView(top >= 0 && top <= window.innerHeight);
            }
        };

        handleScroll(); // Check initially
        window.addEventListener('scroll', handleScroll);


        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
              });
        }
            // Add event listener
        window.addEventListener("resize", handleResize);
        
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const cardVariantsLeft = {
        initial: { x: -50, opacity: 0 },
        animate: { x: 0, opacity: 1 },
    };
    const cardVariantsRight = {
        initial: { x: 50, opacity: 0 },
        animate: { x: 0, opacity: 1 },
    };

    if (id % 2 !== 0 & windowSize.width > 768) {
        return (
            <div ref={ref} className='grid grid-cols-1 pt-4 sm:grid-cols-2'>
                <div className='col-span-1 rounded-md pl-4 pr-4 flex flex-col justify-center align-middle'>
                    <motion.div
                        variants={cardVariantsLeft}
                        initial='initial'
                        animate={isInView ? 'animate' : 'initial'}
                        transition={{ duration: 0.3, delay: 1 }}>
                        <h1 className='text-white text-center text-3xl sm:text-4xl lg:text-6xl lg:leading-normal font-extrabold'>
                            {service}
                        </h1>
                        <p className="text-[#ADB7BE] text-base sm:text-lg mb-6 lg:text-xl">
                            {desc}
                        </p>
                    </motion.div>
                </div>
                <div className='col-span-1 flex flex-col justify-center align-middle'>
                    <motion.div
                        variants={cardVariantsRight}
                        initial='initial'
                        animate={isInView ? 'animate' : 'initial'}
                        transition={{ duration: 0.3, delay: 1 }}>
                        <img
                            src={imgurl}
                            alt='24hrs delivery'
                            width={0}
                            height={0}
                            sizes='100vw'
                            style={{ width: '100%', height: 'auto' }} // optional
                        />
                    </motion.div>
                </div>
            </div>
        );
    } else {
        return (
            <div ref={ref} className='grid grid-cols-1 pt-4 sm:grid-cols-2'>
                <div className='col-span-1 flex flex-col justify-center align-middle'>
                    <motion.div
                        variants={cardVariantsLeft}
                        initial='initial'
                        animate={isInView ? 'animate' : 'initial'}
                        transition={{ duration: 0.5, delay: 1 }}>
                        <img
                            src={imgurl}
                            alt='24hrs delivery'
                            width={0}
                            height={0}
                            sizes='100vw'
                            style={{ width: '100%', height: 'auto' }} // optional
                        />
                    </motion.div>
                </div>
                <div className='col-span-1 rounded-md pl-4 pr-4 flex flex-col justify-center align-middle'>
                    <motion.div
                        variants={cardVariantsRight}
                        initial='initial'
                        animate={isInView ? 'animate' : 'initial'}
                        transition={{ duration: 0.3, delay: 1 }}>
                        <h1 className='text-white text-center text-3xl sm:text-4xl lg:text-6xl lg:leading-normal font-extrabold'>
                            {service}
                        </h1>
                        <p className="text-[#ADB7BE] text-base sm:text-lg mb-6 lg:text-xl">
                            {desc}
                        </p>
                    </motion.div>
                </div>
            </div>
        );
    }
};

export default ServiceCard;
