"use client";
import React from 'react'
import ServiceCard from './ServiceCard'
const servicesData = [
  {
    id: 1,
    name: "24hrs Delivery",
    imgurl: "/images/delivery.jpg",
    desc: "Offering a 24-hour delivery service can be a competitive advantage, providing customers with quick access to products. To ensure success, focus on efficient logistics, streamlined order processing, and a well-organized inventory system to meet the demanding timeline.",
  },
  {
    id: 2,
    name: "Credit Facility",
    imgurl: "/images/credit.jpg",
    desc: "We empower businesses with financial flexibility by offering a credit facility, ensuring easy access to funds as and when required.",
  },
  {
    id:3,
    name: "Inventory Management",
    imgurl: "/images/inventory.jpg",
    desc: "Efficiently track stock levels and optimize warehouse space with our comprehensive inventory management service. Gain control over inventory, reduce costs, and improve operational efficiency."
  },
  {
    id: 4,
    name: "Quality Assurance",
    imgurl: "/images/quality.jpg",
    desc: "We stand by our commitment to quality assurance, offering assurance on any defective products to ensure customer satisfaction and confidence in our products.",
  },
  {
    id: 5,
    name: "Deadstock Solution",
    imgurl: "/images/deadstock.jpg",
    desc: "Unlock value in your inventory with our dead stock solutions, helping businesses efficiently manage and optimize surplus or slow-moving stock.",
  },

  {
    id: 6,
    name: "Clothes For Everyone",
    imgurl: "/images/clothesforeveryone.jpg",
    desc: "Experience inclusive fashion with our diverse range of garments for men, women, and kids. Our collection embraces style and comfort for every member of the family. ",
  },
]

const Services = () => {
  return (
    <section id='services'>
      {/* <h2 className="text-center text-4xl font-bold text-white mt-4 mb-8 md:mb-12">
        Services
      </h2> */}
      <div className=''>
        {
          servicesData.map((service, index) => (
            <ServiceCard 
              key={index} 
              id={service.id}
              service={service.name} 
              imgurl={service.imgurl}
              desc={service.desc} />
          ))
        }
      </div>
    </section>
  )
}

export default Services