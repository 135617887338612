"use client";
import React, {useState} from "react";
// import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import ImageSlider from "./ImageSlider";

const images = [
  '/images/men3D.png',
  '/images/women3D.png',
  '/images/kid3D.png',
  '/images/all3D.png',
];

const HeroSection = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    setShowSnackbar(true);
    // Hide the snackbar after 3 seconds
    setTimeout(() => {
      setShowSnackbar(false);
    }, 3000);
  };

  return (
    <section className="lg:py-16 ">
      <div className="grid grid-cols-1 sm:grid-cols-12">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="col-span-7 place-self-center text-center sm:text-left justify-self-start order-2 sm:order-1"
        >
          <h1 className="text-white mb-4 text-4xl sm:text-5xl lg:text-8xl lg:leading-normal font-extrabold">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary">
              Clothes for{" "}
            </span>
            <br></br>
            <TypeAnimation
              sequence={[
                "Men",
                1500,
                "Women",
                1500,
                "Children",
                1500,
                "Everyone",
                1500,
              ]}
              wrapper="span"
              speed={50}
              repeat={Infinity}
            />
          </h1>
          <p className="text-[#ADB7BE] text-base sm:text-lg mb-6 lg:text-xl">
          Experience inclusive fashion with our diverse range of garments for men, women, and kids. Our collection embraces style and comfort for every member of the family.

          </p>
          <div className="flex">
            {/* <Link
              href="/#contact"
              className="px-6 inline-block py-3 w-full sm:w-fit rounded-full mr-4 bg-gradient-to-br from-primary-500 to-secondary-500 hover:bg-slate-200 text-white"
            >
              Contact
            </Link> */}
            <a
              href="#"
              onClick={handleClick}
              className="px-1 inline-block py-1 w-full sm:w-fit rounded-full bg-gradient-to-br from-primary to-secondaryS hover:bg-slate-800 text-white mt-3"
            >
              <span className="block bg-[#121212] hover:bg-slate-800 rounded-full px-5 py-2">
                Buy ↗
              </span>
            </a>
            {showSnackbar && (
            <div className=" text-white rounded">
              <p className="text-[#ADB7BE] text-base sm:text-lg mb-6 lg:text-xl">Coming Soon!</p>
            </div>
          )}
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="col-span-5 place-self-center mt-4 lg:mt-0 order-1 sm:order-2"
        >
          <div className="rounded-full bg-[#181818] w-[250px] h-[250px] lg:w-[400px] lg:h-[400px] relative">
            {/* <Image
              src="/images/herosection-image.png"
              alt="hero image"
              className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              width={300}
              height={300}
            /> */}
            <ImageSlider images={images} interval={2100} />
          </div>
          
        </motion.div>
      </div>
    </section>
  );
};

export default HeroSection;
