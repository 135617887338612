import React from "react";
// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer border z-10 border-t-[#33353F] border-l-transparent border-r-transparent text-white" id="footer">
      <div className="container p-4 flex flex-col justify-between text-center sm:p-12 sm:flex-row">
      {/* <Link
          href={"/"}
          className="text-2xl md:text-5xl text-white font-semibold"
        >
          <Image 
            alt="logo" 
            src={"/images/logo-dark-crop-no-bg.png"} 
            width={0}
            height={0}
            sizes="100vw"
            style={{ width: '100%', height: '50px' }} // optional
          />
        </Link> */}
        <div className="mb-4 order-1 sm:order-1">
          <p className="text-slate-600">L ext 327,</p>
          <p className="text-slate-600">Uttam Nagar, Mohan Garden,</p>
          <p className="text-slate-600">New Delhi, 110059</p>
        </div>
        <div className="flex flex-col justify-center align-middle mb-4 order-3 sm:order-2">
          <p className="text-slate-600">Made with ❤️ in India</p>
          <p className="text-slate-600 text-center">Copyright © 2024 Movkart, Inc. All rights reserved.</p>

        </div>

        <div className=" text-slate-600 mb-4 order-2 sm:order-3">
          {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z" />
          </svg>           */}
          <p className="text-slate-600">Contact us</p>
          <p className="text-slate-600">(+91) 9990531991</p>
          <p className="text-slate-600">(+91) 9990531993</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
