import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const ImageSlider = ({ images, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, [images.length, interval]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {images.map((image, index) => (
        <motion.img
          key={index}
          src={image}
          alt={`Image ${index}`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: index === currentIndex ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            width: '100%',
            height: '100%',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: index === currentIndex ? 1 : 0 }}
          exit={{ opacity: 0 }}
        />
      ))}
    </div>
  );
};

export default ImageSlider;
